import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { selectLoadingUserInfo, selectUserInfo } from "../store/app/selectors";

export const useAuth = () => {
  const userInfo = useSelector(selectUserInfo);
  const loadingUserInfo = useSelector(selectLoadingUserInfo);

  return { isAuthenticated: !isEmpty(userInfo), userInfo, loadingUserInfo };
};
