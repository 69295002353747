import cartServices from '@/kytesoft-client/services/cartServices';
import productServices from '@/kytesoft-client/services/productServices';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { find, findIndex, get, head, isEmpty, map, reject, uniq } from 'lodash';
import { setCart } from './slice';

const VERSION_KEY = 'version';
const VERSION = '19/12/2023-1.0.0';

const getStorageCart = () => {
  try {
    if (localStorage.getItem(VERSION_KEY) !== VERSION) throw new Error('version invalid!');

    return JSON.parse(localStorage.getItem('cart'));
  } catch (error) {
    localStorage.setItem('cart', '[]');
    localStorage.setItem(VERSION_KEY, VERSION);
    return [];
  }
};

export const initCartThunk = createAsyncThunk('cart/init', async (_, { dispatch }) => {
  try {
    const items = getStorageCart();
    if (isEmpty(items)) return;

    const ids = uniq(map(items, 'productId'));
    const response = await cartServices.checkValidCart({ ids });
    const products = get(response, 'items', []);

    const results = map(items, (item) => {
      const { id, productId, quantity, attributeId } = item;

      const product = find(products, { id: productId });
      if (isEmpty(product)) return { ...item, notFound: true };

      const { options, attributes, name, discountRate, discount, slug } = product;
      const hasAttribute = !isEmpty(options) && !isEmpty(attributes);

      if (!attributeId && hasAttribute) return { ...item, noAttribute: true };
      if (attributeId && !hasAttribute) return { ...item, noAttribute: true };

      const attribute = find(attributes, { id: attributeId });
      const optionIndex = findIndex(
        reject(get(head(options), 'values', []), (val) => !val),
        (val) => val === head(attribute?.options),
      );
      const thumbnail = hasAttribute
        ? get(head(options), `images.[${optionIndex}]`) || get(head(options), `images.[0]`)
        : get(product, 'thumbnail') || head(get(product, 'images'));

      const price = hasAttribute ? get(attribute, 'price') : get(product, 'price') || 0;
      const productQuantity = hasAttribute
        ? get(attribute, 'quantity') || 0
        : get(product, 'quantity') || 0;

      let salePrice = price;
      if (discountRate) {
        salePrice = (price / 100) * (100 - discountRate);
      } else if (discount) {
        salePrice = price - discount;
      }

      const totalAmount = quantity * salePrice;

      return {
        id,
        productId,
        ...(attributeId && { attributeId, options: attribute.options }),
        name,
        slug,
        price,
        salePrice,
        discount,
        discountRate,
        quantity,
        totalAmount,
        thumbnail,
        productQuantity,
      };
    });

    dispatch(setCart(results));
  } catch (error) {}
});

export const getProductThunk = createAsyncThunk(
  'cart/getProduct',
  async (slug, { rejectWithValue }) => {
    try {
      const product = await productServices.getProduct({ slug });
      return product;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
