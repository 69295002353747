// @ts-nocheck
import { useWishlistsQuery } from '@/kytesoft-client/features/wishlists';
import { cloneDeep, filter, get, includes, isEmpty, some } from 'lodash';
import { useRouter } from 'next/router';
import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import { ENABLE_CALL_WISHLIST } from './constants';
import quickViewContext from './quickViewContext';
import wishlistContext from './wishlistContext';

interface IContextProvidersProps {
  children?: ReactNode;
}

export const ContextProviders: FC<IContextProvidersProps> = ({ children }) => {
  const router = useRouter();
  const { pathname } = router;

  const [isOpenQuickViewModal, setIsOpenQuickViewModal] = useState<boolean>(false);
  const [quickViewData, setQuickViewData] = useState(undefined);
  const [isOpenWishlist, setIsOpenWishlist] = useState<boolean>(false);
  const [wishlists, setWishlists] = useState<any>([]);

  const { data: wishlistRes, isFetching } = useWishlistsQuery(
    // @ts-ignore
    {
      page: 1,
      limit: 100,
      enabled: includes(ENABLE_CALL_WISHLIST, pathname),
    },
  );

  const handleCloseQuickViewModal = () => {
    setIsOpenQuickViewModal(false);
    setQuickViewData(undefined);
  };

  const handleOpenQuickViewModal = (data: any) => {
    setIsOpenQuickViewModal(true);
    setQuickViewData(data);
  };

  const handleOpenWishlist = () => {
    setIsOpenWishlist(true);
  };

  const handleCloseWishlist = () => {
    setIsOpenWishlist(false);
  };

  const addToWishlist = (data: any) => {
    const newWishlists = cloneDeep(wishlists);
    newWishlists.unshift({
      product: data,
    });

    setWishlists(newWishlists);
  };

  const removeFromWishlist = (productId?: string) => {
    if (isEmpty(productId)) return;

    const newWishlists = cloneDeep(wishlists);

    const result = filter(newWishlists, (item) => get(item, 'product.id') !== productId);

    setWishlists(result);
  };

  const isInWishlist = useCallback(
    (id?: string) => {
      const result = some(wishlists, (item) => get(item, 'product.id') === id);

      return result;
    },
    [wishlists],
  );

  useEffect(() => {
    setWishlists(get(wishlistRes, 'items', []));
  }, [isFetching, wishlistRes]);

  return (
    <>
      <quickViewContext.Provider
        value={{
          data: quickViewData,
          handleCloseModal: handleCloseQuickViewModal,
          isOpen: isOpenQuickViewModal,
          handleOpenModal: handleOpenQuickViewModal,
        }}
      >
        <wishlistContext.Provider
          value={{
            isOpen: isOpenWishlist,
            handleClose: handleCloseWishlist,
            handleOpen: handleOpenWishlist,
            wishlists,
            addToWishlist,
            removeFromWishlist,
            isInWishlist,
          }}
        >
          {children}
        </wishlistContext.Provider>
      </quickViewContext.Provider>
    </>
  );
};
