import { privateRequest } from '../request';

const wishlistServices = {
  getWishlists: ({ page, limit }) =>
    privateRequest.request({
      method: 'GET',
      url: '/me/wishlists',
      params: {
        page,
        limit,
      },
    }),

  addWishlist: (id) =>
    privateRequest.request({
      method: 'POST',
      url: '/me/wishlists',
      data: {
        productId: id,
      },
    }),

  deleteWishlist: (id) =>
    privateRequest.request({
      method: 'DELETE',
      url: `/me/wishlists/${id}`,
    }),
};

export default wishlistServices;
