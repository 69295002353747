export const initialState = {
  appSettings: {},
  userInfo: {},
  loadingUserInfo: false,

  registerToken: null,

  forgetPasswordToken: null,
  forgetPasswordCode: null,

  socketClient: null,
};

export const defaultCurrencyConfig = {
  locale: 'vi-VN',
  currency: 'VND',
};
