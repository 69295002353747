import { useAuth } from '@/kytesoft-client/auth';
import wishlistServices from '@/kytesoft-client/services/wishlistServices';
import { useQuery } from '@tanstack/react-query';
import { PAGE_SIZE } from '../constants';

export const getWishlistsQueryConfig = ({
  page = 1,
  limit = PAGE_SIZE,
  options = {},
  queryKey = {},
  ...rest
} = {}) => ({
  queryKey: ['/wishlists', { page, limit, ...queryKey }],
  queryFn: () => wishlistServices.getWishlists({ page, limit }),
  options: { retry: 1, ...options },
  ...rest,
});

export const useWishlistsQuery = ({ page, limit, options, ...rest } = {}) => {
  const { isAuthenticated } = useAuth();
  const { enabled } = rest;

  return useQuery(
    getWishlistsQueryConfig({ page, limit, options, ...rest, enabled: isAuthenticated && enabled }),
  );
};

export const prefetchWishlistsQuery = async (queryClient, options) => {
  await queryClient.prefetchQuery(getWishlistsQueryConfig(options));
};
