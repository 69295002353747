import { publicRequest } from "../request";

const appServices = {
  getAppSettings: () => {
    return publicRequest.request({
      method: "GET",
      url: "/apps",
    });
  },

  subscribe: ({ email }) => {
    return publicRequest.request({
      method: "POST",
      url: "/subscribes",
      data: { email },
    });
  },

  generateUploadUrl: ({ isFile, usedFor, fileName }) => {
    return publicRequest.request({
      method: "POST",
      url: isFile ? "/media/generate-file-upload-url" : "/media/generate-image-upload-url",
      data: { usedFor, fileName },
    });
  },

  upload: ({ url, file, contentType }) => {
    return axios.request({
      method: "PUT",
      url: url,
      headers: {
        "Content-Type": contentType,
      },
      data: file,
    });
  },

  getSitemap: () => {
    return publicRequest.request({
      method: "GET",
      url: "/sitemap",
    });
  },
};

export default appServices;
