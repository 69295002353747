import { ContextProviders } from '@/contexts';
import { KytesoftProvider } from '@/kytesoft-client/providers';
import CartDrawer from '@/layout/CartDrawer';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import appStyles from '@scss/main.scss';
import { routes } from '@utils/routes';
import { vi } from 'date-fns/locale';
import { DefaultSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import defaultSeoConfig from '../../default-seo.json';

const MainMenu = dynamic(() => import('@/layout/MainMenu'));
const MidMenu = dynamic(() => import('@/layout/MidMenu'));

const Footer = dynamic(() => import('@/layout/Footer'), { ssr: false });
const MenuBottom = dynamic(() => import('@/layout/MenuBottom'), { ssr: false });
const QuickViewModal = dynamic(() => import('@components/compound/Modal/QuickViewModal'), {
  ssr: false,
});
const WishlistPopup = dynamic(() => import('@components/compound/Modal/WishlistPopup'), {
  ssr: false,
});
const MenuDrawer = dynamic(() => import('@components/compound/Drawer/MenuDrawer'), { ssr: false });
const ToastContainer = dynamic(() => import('react-toastify').then((resp) => resp.ToastContainer), {
  ssr: false,
});

export default function MyApp({ Component, ...props }: any) {
  const router = useRouter();
  const [isOpenMenuDrawer, setIsOpenMenuDrawer] = useState(false);
  const [isOpenCart, setIsOpenCart] = useState(false);

  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setIsOpenCart(false);
    setIsOpenMenuDrawer(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router]);

  return (
    <>
      <style jsx>{appStyles}</style>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={vi}>
        <KytesoftProvider
          privateRoutePrefix="/my-account"
          authRoutes={[routes.AUTH, routes.LOST_PASSWORD, routes.REGISTER_VERIFY]}
          hydrateState={props.pageProps.dehydratedState}
        >
          <DefaultSeo {...(defaultSeoConfig as any)} />
          <ToastContainer />

          <ContextProviders>
            <MenuDrawer open={isOpenMenuDrawer} onClose={() => setIsOpenMenuDrawer(false)} />
            <CartDrawer open={isOpenCart} onClose={() => setIsOpenCart(false)} />

            <QuickViewModal />
            <WishlistPopup />

            <div className="ks-header-layout">
              <div className="icon -bottom" />
              <div className="icon -top" />
              <div className="ks-container">
                <MidMenu
                  onClickMenu={() => setIsOpenMenuDrawer(true)}
                  onClickCart={() => setIsOpenCart(true)}
                />

                <MainMenu />
              </div>
            </div>

            <Component {...props.pageProps} key={router.asPath} />

            <MenuBottom />
            <Footer />
          </ContextProviders>
        </KytesoftProvider>
      </LocalizationProvider>
    </>
  );
}
