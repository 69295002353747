import { createContext } from 'react';

interface IWishlistContext {
  isOpen: boolean;
  handleClose: () => void;
  handleOpen: (data: any) => void;
  wishlists: any;
  addToWishlist: (data: any) => void;
  removeFromWishlist: (productId?: string) => void;
  isInWishlist: (id?: string) => boolean;
}

const wishlistContext = createContext<IWishlistContext>({
  isOpen: false,
  handleClose: () => null,
  handleOpen: () => null,
  wishlists: [],
  addToWishlist: () => null,
  removeFromWishlist: () => null,
  isInWishlist: () => false,
});

export default wishlistContext;
