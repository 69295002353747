import { createContext } from 'react';

interface IQuickViewContext {
  isOpen: boolean;
  data?: any;
  handleCloseModal: () => void;
  handleOpenModal: (data: any) => void;
}

const quickViewContext = createContext<IQuickViewContext>({
  isOpen: false,
  data: undefined,
  handleCloseModal: () => null,
  handleOpenModal: () => null,
});

export default quickViewContext;
