import { GoogleOAuthProvider } from '@react-oauth/google';
import { Hydrate, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Provider } from 'react-redux';
import store from '../store/configureStore';

const AppSession = dynamic(() => import('../contexts/appContext'), { ssr: false });

export const KytesoftProvider = ({
  children,
  hydrateState,
  authRoutes = ['/login', '/register'],
  privateRoutePrefix = '/profile',
}) => {
  const queryClientRef = useRef();

  if (!queryClientRef.current)
    queryClientRef.current = new QueryClient({
      defaultOptions: {
        queries: {
          retry: false,
          refetchOnReconnect: false,
          refetchOnWindowFocus: false,
          refetchInterval: false,
          refetchIntervalInBackground: false,
          refetchOnMount: false,
        },
      },
    });

  const GoogleProvider = useCallback(
    (children) =>
      process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ? (
        <GoogleOAuthProvider clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}>
          {children}
        </GoogleOAuthProvider>
      ) : (
        <>{children}</>
      ),
    [],
  );

  useLoadFacebookScript();

  return (
    <QueryClientProvider client={queryClientRef.current}>
      <Hydrate state={hydrateState}>
        <Provider store={store}>
          {GoogleProvider(children)}

          <AppSession authRoutes={authRoutes} privateRoutePrefix={privateRoutePrefix} />
        </Provider>
      </Hydrate>
    </QueryClientProvider>
  );
};

function useLoadFacebookScript({ options = {} } = {}) {
  const { nonce, onScriptLoadSuccess, onScriptLoadError } = options || {};

  const [scriptLoadedSuccessfully, setScriptLoadedSuccessfully] = useState(false);

  const onScriptLoadSuccessRef = useRef(onScriptLoadSuccess);
  onScriptLoadSuccessRef.current = onScriptLoadSuccess;

  const onScriptLoadErrorRef = useRef(onScriptLoadError);
  onScriptLoadErrorRef.current = onScriptLoadError;

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_FACEBOOK_APP_ID) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: 'v19.0',
        });

        FB.AppEvents.logPageView();
      };
    }

    const scriptTag = document.createElement('script');
    scriptTag.src = 'https://connect.facebook.net/en_US/sdk.js';
    scriptTag.async = true;
    scriptTag.defer = true;
    scriptTag.nonce = nonce;
    scriptTag.onload = () => {
      setScriptLoadedSuccessfully(true);
      onScriptLoadSuccessRef.current?.();
    };
    scriptTag.onerror = () => {
      setScriptLoadedSuccessfully(false);
      onScriptLoadErrorRef.current?.();
    };

    document.body.appendChild(scriptTag);

    return () => {
      document.body.removeChild(scriptTag);
    };
  }, [nonce]);

  return scriptLoadedSuccessfully;
}
