import { privateRequest, publicRequest } from '../request';

const authServices = {
  login: async ({ username, password }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/login',
      data: { username, password },
    });
  },

  googleLogin: ({ accessToken }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/oauth/google',
      data: { accessToken },
    });
  },

  facebookLogin: ({ accessToken }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/oauth/facebook',
      data: { accessToken },
    });
  },

  refreshToken: ({ token }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/refresh-token',
      data: { token },
    });
  },

  getMe: () => {
    return privateRequest.request({
      method: 'GET',
      url: '/me',
    });
  },

  logout: () => {
    return privateRequest.request({
      method: 'POST',
      url: '/logout',
    });
  },

  clientGetToken: () => {
    return publicRequest.request({
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_SITE_URL,
      url: '/api/token',
    });
  },

  clientGetMe: () => {
    return publicRequest.request({
      method: 'GET',
      baseURL: process.env.NEXT_PUBLIC_SITE_URL,
      url: '/api/user',
    });
  },

  clientLogin: (data) => {
    return publicRequest.request({
      method: 'POST',
      baseURL: process.env.NEXT_PUBLIC_SITE_URL,
      url: '/api/login',
      data,
    });
  },

  clientLogout: () => {
    return publicRequest.request({
      method: 'POST',
      baseURL: process.env.NEXT_PUBLIC_SITE_URL,
      url: '/api/logout',
    });
  },

  clientRegister: (data) => {
    return publicRequest.request({
      method: 'POST',
      url: '/register',
      data,
    });
  },

  clientRegisterResendOtp: ({ token }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/register/basic/resend-otp',
      data: { token },
    });
  },

  clientRegisterVerify: ({ token, code }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/register/basic/verify',
      data: { token, code },
    });
  },

  forgetPassword: ({ username }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/forget-password',
      data: { username },
    });
  },

  forgetPasswordResendOtp: ({ token }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/forget-password/resend-otp',
      data: { token },
    });
  },

  forgetPasswordVerify: ({ token, code }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/forget-password/verify',
      data: { token, code },
    });
  },

  resetPassword: ({ password, confirmPassword, token, code }) => {
    return publicRequest.request({
      method: 'POST',
      url: '/reset-password',
      data: { password, confirmPassword, token, code },
    });
  },

  verify: ({ code, token, type, params = {} }) => {
    return publicRequest.request({
      url: '/verify-auth',
      method: 'POST',
      data: { code, token, type },
      params,
    });
  },
};

export default authServices;
