import { useAppSettingsQuery } from '@/kytesoft-client/features/appSettings';
import { useDevice } from '@hooks/useDevice';
import imageStyles from '@scss/components/image.scss';
import classNames from 'classnames';
import { includes, isEmpty } from 'lodash';
import NextImage from 'next/image';
import { ImgHTMLAttributes, useCallback, useEffect, useState } from 'react';

export interface IImageProps extends Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> {
  src: string;
  hideOnError?: boolean;
  defaultImage?: string;
  loadingSize?: 'sm' | 'md' | 'lg';
  className?: string;
  objectFit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  useNextImage?: boolean;
  quantity?: number;
  [key: string]: any;
}

const imagePathRegex = /\.(jpg|jpeg|png|gif|bmp|svg)$/i;

export function KaImage({
  src,
  hideOnError,
  defaultImage,
  className,
  loadingSize = 'md',
  objectFit,
  useNextImage,
  quantity,
  ...props
}: IImageProps) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(!useNextImage);
  const [imageUrl, setImageUrl] = useState<string>('');
  const { data: appSettings }: any = useAppSettingsQuery();

  const getImageUrl = useCallback(({ src, width, height, quantity }: any) => {
    if (includes(src, 'https://')) return src;

    let url = `${appSettings?.s3Config?.cdnDomain}${src}?tr=q-${quantity},di=error.png,f=webp`;

    if (width) url += `,w-${width}`;
    if (height) url += `,h-${height}`;

    return url;
  }, []);

  useEffect(() => {
    if (useNextImage) return;

    let isMounted = true;
    setIsLoading(true);

    if (!imagePathRegex.test(src) || isEmpty(src)) {
      if (isMounted) {
        setIsError(true);
        setIsLoading(false);
        return;
      }
    }

    const url = getImageUrl({ src, quantity, width: props.width, height: props.height });
    const img = new Image();

    img.onerror = () => {
      if (isMounted) {
        setIsError(true);
        setIsLoading(false);
      }
    };
    img.onload = () => {
      if (isMounted) {
        setImageUrl(url);
        setIsLoading(false);
      }
    };
    img.src = url;

    return () => {
      isMounted = false;
    };
  }, [src, useNextImage]);

  useEffect(() => {
    if (imageUrl) setIsLoading(false);
  }, [imageUrl]);

  if (isError && hideOnError) return null;

  return useNextImage ? (
    <>
      <style jsx>{imageStyles}</style>
      {/* @ts-ignore */}
      <NextImage
        className={classNames('ks-image', `-${objectFit}`, className)}
        src={(isError ? defaultImage : src) || ''}
        alt={props.alt || 'ks-img'}
        onError={() => setIsError(true)}
        loader={({ src }) => {
          if (includes(src, 'https://')) return src;

          let url = `${appSettings?.s3Config?.cdnDomain}${src}?tr=q-${quantity},di-error.png,f-webp`;
          if (props.width) url += `,w-${props.width}`;

          if (props.height) url += `,h-${props.height}`;

          return url;
        }}
        quality={quantity}
        fill={!(props.width && props.height)}
        priority
        {...props}
      />
    </>
  ) : (
    <>
      <style jsx>{imageStyles}</style>
      <img
        className={classNames('ks-image', `-${objectFit}`, className)}
        src={isError ? defaultImage : imageUrl}
        alt={props.alt || 'ks-img'}
        {...props}
      />
    </>
  );
}

KaImage.defaultProps = {
  defaultImage: `/images/error.png`,
  className: '',
  quantity: 75,
  useNextImage: false,
};
