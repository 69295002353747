import { useCart } from '@/kytesoft-client/features/useCart';
import { usePrice } from '@/kytesoft-client/features/usePrice';
import { Button } from '@components/primitive/Button';
import { KaImage } from '@components/primitive/Image';
import { Link } from '@components/primitive/Link';
import { Drawer } from '@mui/material';
import cartDrawerStyles from '@scss/components/cart-drawer.scss';
import { routes } from '@utils/routes';
import { map, size } from 'lodash';
import { useRouter } from 'next/router';
import { FC } from 'react';

interface ICartDrawerProps {
  open: boolean;
  onClose: () => void;
}

const CartDrawer: FC<ICartDrawerProps> = ({ open, onClose }) => {
  const { items, removeItemFromCart, totalAmount } = useCart();
  const { formatPrice } = usePrice();

  const router = useRouter();

  return (
    <>
      <style jsx>{cartDrawerStyles}</style>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className="ks-cart-drawer">
          <div className="header">
            <span className="title">Giỏ hàng</span>
            <span className="close" onClick={onClose}>
              ĐÓNG <i className="fa-light fa-xmark" />
            </span>
          </div>

          <div className="content">
            <ul className="products">
              {size(items) !== 0 ? (
                map(items, ({ thumbnail, name, salePrice, quantity, id, slug }) => (
                  <li className="item" key={`cart-${id}`}>
                    <Link
                      title={name}
                      href={{
                        pathname: routes.PRODUCT,
                        query: { slug },
                      }}
                      className="thumbnail"
                    >
                      <KaImage
                        src={thumbnail}
                        alt="cart"
                        objectFit="contain"
                        ratio="square"
                        className="image"
                        useNextImage
                        sizes="100%"
                        priority
                      />
                    </Link>

                    <div className="info">
                      <p className="name">
                        <Link
                          title={name}
                          href={{
                            pathname: routes.PRODUCT,
                            query: { slug },
                          }}
                          className="inner line-clamp-2"
                        >
                          {name}
                        </Link>
                      </p>
                      <div className="group">
                        {quantity} x <span className="price">{formatPrice(salePrice)} đ</span>
                      </div>
                    </div>
                    <span className="close" onClick={() => removeItemFromCart({ id })}>
                      <i className="fa-light fa-xmark icon" />
                    </span>
                  </li>
                ))
              ) : (
                <p className="empty">Giỏ hàng rỗng</p>
              )}
            </ul>
          </div>

          {size(items) > 0 && (
            <div className="footer">
              <div className="total">
                <span className="subtotal">Tổng:</span>
                <span className="price">{formatPrice(totalAmount)} đ</span>
              </div>
              <Button
                fullWidth
                color="light"
                className="button cart"
                onClick={() => router.push({ pathname: routes.CART })}
              >
                Xem giỏ hàng
              </Button>
              <Button
                onClick={() => router.push(routes.CHECKOUT)}
                fullWidth
                className="button checkout"
              >
                Thanh toán
              </Button>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
};

export default CartDrawer;
